import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';


const StyleWrapper=styled.div`

width:100%;
display: flex;
justify-content: center;
align-content: center;
margin-top:30px;
position:relative;
`

const Div=styled.div`
width:80%;
display: flex;
justify-content: center;
align-content: center;
`

const H1 = styled.div`
height: auto;
color: ${({isBlue}) => isBlue ? ({theme}) => theme.blue : ({theme}) => theme.blackfont};
font-size: ${({theme}) => theme.font.size.xxm};
margin-top: ${({isTop}) => isTop ? '25px' : '10px'};
font-weight: 600;
line-height: 1.4;
margin-bottom: 30px;
`

const StyledP = styled.div`
font-size: ${({theme}) => theme.font.size.ss};
line-height:1.6;
color: ${({theme}) => theme.blackfont};
margin-bottom: 20px;
font-weight:500;

&:last-child{
  margin-bottom: 50px;
  }
`

const  About = () => {
  const data = useStaticQuery(graphql`
  query{
  allDatoCmsAbout {
    nodes {
      id
      title
      description {
        ... on DatoCmsHeadtitleabout {
          id
          headtitle
        }
        ... on DatoCmsDescription {
          id
          texthtml
        }
      }
    }
  }
}
`)

  return (
       <StyleWrapper>

      {data.allDatoCmsAbout.nodes.map(Abouts => (
          <Div key={ Abouts.id}>
           <h1> {About.title}</h1>

           <div>
          {Abouts.description.map(item => {
            const itemKey = Object.keys(item)[1];

            switch (itemKey) {
                 case 'headtitle':
                  return <H1 key={item.id} dangerouslySetInnerHTML={{ __html: item.headtitle }} />;
                 case 'texthtml':
                  return <StyledP key={item.id} dangerouslySetInnerHTML={{ __html: item.texthtml }} />;
              default:
                return null;
            }
          })}
          </div>

      </Div>
      ))}
       </StyleWrapper>


);
}

export default  About;

