import React from "react"
import styled from 'styled-components';
import SEO from 'components/SEO/seo'
import MainTemplate from 'templates/MainTemplate'
import About from "../components/About/About";


const StyledAbout = styled.div`
width: 100%;
display: flex;
justify-content: center;
padding-top: 20px;
background-color: ${({theme}) => theme.body};
font-family: ${({theme}) => theme.font.family.Montserrat};
${({theme}) => theme.media.desktop} {
width: 100%;
display: flex;
justify-content: center;
padding-top: 50px;
background-color: ${({theme}) => theme.body};
font-family: ${({theme}) => theme.font.family.Montserrat};
}`
const StyleWrapper = styled.div`
width: 100%;
max-width: 1100px;
height: auto;
margin-bottom: 10px;
${({theme}) => theme.media.desktop} {
width: 90%;
height: auto;
margin-bottom: 10px;}
${({theme}) => theme.media.large} {
width: 100%;
max-width: 1100px;
height: auto;
margin-bottom: 10px;
}
`

const StyledInner = styled.div`
display:flex;
flex-direction: column;
overflow: hidden;
padding: 15px;
padding-bottom: 0;
border: 1px solid ${({theme}) => theme.border};
background-color: rgba(255, 255, 255, 1);
margin-bottom: 20px;

`

const StyledTitile = styled.div`
width: auto;
height:auto;
display: flex;
align-items:center;
padding:11px 18px 6px 18px;
background-color: rgba(0, 0, 0, 0.1);
`

const H1=styled.h1`
font-weight:600;
font-size: ${({theme}) => theme.font.size.xm};

`

const Contact = () => (
  <MainTemplate>
     <SEO title="O nas" />
    <StyledAbout>
      <StyleWrapper>

        <StyledTitile><H1>O nas</H1></StyledTitile>
          <StyledInner>
          <About />
          </StyledInner>
  </StyleWrapper>
  </StyledAbout>

  </MainTemplate>
)

export default Contact
